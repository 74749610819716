import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useAccountsCollection } from '../DBProvider'
import { Org } from '@/lib/schemas/OrgSchema'

export default function OrgAccountsTab({ org }: Readonly<{ org: Org }>) {
  const accountsCollection = useAccountsCollection()

  const { result: accounts, isFetching } = useRxQuery(accountsCollection.find({ selector: { orgId: org.id } }), {
    json: true,
  })

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Account</th>
          </tr>
        </thead>
        <tbody>
          {accounts?.map((account) => {
            return (
              <tr key={account.id}>
                <td>
                  <Link className="hover:text-primary" to={`accounts/${account.id}`}>
                    {account.name}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
