import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useDevicesCollection } from '../DBProvider'
import { Floor } from '@/lib/schemas/FloorSchema'

export default function FloorDevicesTab({ floor }: Readonly<{ floor: Floor }>) {
  const devicesCollection = useDevicesCollection()

  const { result: devices, isFetching } = useRxQuery(devicesCollection.find({ selector: { floorId: floor.id } }), {
    json: true,
  })

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Device</th>
          </tr>
        </thead>
        <tbody>
          {devices?.map((device) => {
            return (
              <tr key={device.id}>
                <td>
                  <Link className="hover:text-primary" to={`devices/${device.id}`}>
                    {device.id}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
