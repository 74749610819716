import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const siteSchemaLiteral = {
  title: 'Site Schema',
  describe: 'Describes a site',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: { type: 'string', maxLength: 36 },
    orgId: { type: 'string' },
    accountId: { type: 'string' },
    name: { type: 'string' },
    address: { type: 'string' },
    city: { type: 'string' },
    state: { type: 'string' },
    zip: { type: 'string' },
    createdDate: { type: 'string', format: 'date-time' },
    createdByUserId: { type: 'string' },
    updatedDate: { type: 'string', format: 'date-time' },
    updatedByUserId: { type: 'string' },
    notes: { type: 'string' },
    isArchived: { type: 'boolean' },
    defaultTicketAssignedToUserId: { type: 'string' },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'accountId', 'name', 'isArchived', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(siteSchemaLiteral)

// aggregate the document type from the schema
export type Site = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const siteSchema: RxJsonSchema<Site> = siteSchemaLiteral

export type SiteDocument = RxDocument<Site>

export type SiteCollection = RxCollection<Site>
