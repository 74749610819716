import { useParams } from 'react-router'
import BuildingFloorsTab from './BuildingFloorsTab'
import BuildingInfoTab from './BuildingInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { Paper, Tabs, Tab } from '@mui/material'
import { useEffect, useState } from 'react'
import TabPanel from '../TabPanel'
import { useBuildingsCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info', 'floors']

export default function BuildingPage() {
  const { buildingId, tabId = 'info' } = useParams()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)

  const buildingsCollection = useBuildingsCollection()
  const { result: buildings, isFetching } = useRxQuery(buildingsCollection.findOne(buildingId), { json: true })

  useEffect(() => {
    setTabValue(tabValues.indexOf(tabId))
  }, [tabId])

  if (isFetching) return <></>

  const building = buildings?.[0]
  if (!building) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath = newValue === 0 ? `/buildings/${buildingId}` : `/buildings/${buildingId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{building.name}</h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Floors" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <BuildingInfoTab building={building} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <BuildingFloorsTab building={building} />
        </TabPanel>
      </Paper>
    </>
  )
}
