import React, { createContext, use, useContext, useEffect, useMemo, useState } from 'react'
import { Provider, useRxCollection, useRxDB } from 'rxdb-hooks'
import { createDb, initializeDb, performSync, SiteOwlDatabase } from '@/lib/rxdb'
import { RxCollection } from 'rxdb'
import { Account } from '@/lib/schemas/AccountSchema'
import { User } from '@/lib/schemas/UserSchema'
import { Org } from '@/lib/schemas/OrgSchema'
import { Building } from '@/lib/schemas/BuildingSchema'
import { AccountMember } from '@/lib/schemas/AccountMemberSchema'
import { Device } from '@/lib/schemas/DeviceSchema'
import { DeviceImage } from '@/lib/schemas/DeviceImageSchema'
import { DeviceVersion } from '@/lib/schemas/DeviceVersionSchema'
import { EquipmentType } from '@/lib/schemas/EquipmentTypeSchema'
import { EquipmentTypeCategory } from '@/lib/schemas/EquipmentTypeCategorySchema'
import { Favorite } from '@/lib/schemas/FavoriteSchema'
import { FavoriteDevice } from '@/lib/schemas/FavoriteDeviceSchema'
import { FavoriteTask } from '@/lib/schemas/FavoriteTaskSchema'
import { Floor } from '@/lib/schemas/FloorSchema'
import { Location } from '@/lib/schemas/LocationSchema'
import { Project } from '@/lib/schemas/ProjectSchema'
import { ProjectFileAttachment } from '@/lib/schemas/ProjectFileAttachmentSchema'
import { ProjectFloor } from '@/lib/schemas/ProjectFloorSchema'
import { ProjectMember } from '@/lib/schemas/ProjectMemberSchema'
import { ServiceTicket } from '@/lib/schemas/ServiceTicketSchema'
import { ServiceTicketComment } from '@/lib/schemas/ServiceTicketCommentSchema'
import { ServiceTicketFileAttachment } from '@/lib/schemas/ServiceTicketFileAttachmentSchema'
import { ServiceTicketWatcher } from '@/lib/schemas/ServiceTicketWatcherSchema'
import { Site } from '@/lib/schemas/SiteSchema'
import { SiteMember } from '@/lib/schemas/SiteMemberSchema'
import { Task } from '@/lib/schemas/TaskSchema'
import { TaskImage } from '@/lib/schemas/TaskImageSchema'
import { TaskType } from '@/lib/schemas/TaskTypeSchema'
import { UserAccountPreferences } from '@/lib/schemas/UserAccountPreferencesSchema'
import { UserDeviceToken } from '@/lib/schemas/UserDeviceTokenSchema'
import { UserFloorPreferences } from '@/lib/schemas/UserFloorPreferencesSchema'
import { OrgMember } from '@/lib/schemas/OrgMemberSchema'
import { useFirestore } from './FirebaseProvider'
import { useFirebaseAuthState } from './auth/FirebaseUserProvider'

type DBState = {
  db?: SiteOwlDatabase
  dbIsLoading: boolean
}

export const DBContext = createContext<DBState>({ db: undefined, dbIsLoading: true })

export default function DBProvider({
  defaultDb = undefined,
  defaultDbIsLoading = true,
  children,
}: Readonly<{
  defaultDb?: SiteOwlDatabase
  defaultDbIsLoading?: boolean
  children: React.ReactNode
}>) {
  const [db, setDb] = useState<SiteOwlDatabase | undefined>(defaultDb)
  const [dbIsLoading, setDbIsLoading] = useState(defaultDbIsLoading)
  const firestore = useFirestore()
  const {
    firebaseAuth: { firebaseUserId, firebaseAuthIsLoading },
  } = useFirebaseAuthState()

  useEffect(() => {
    if (firebaseAuthIsLoading) {
      console.log('DBProvider: firebase auth is still loading, just wait longer')
      return
    }

    if (!firebaseUserId) {
      console.log('DBProvider: firebase is done loading, but we dont have a userId, so dont load the rxdb')
      setDbIsLoading(false)
      return
    }

    // RxDB instantiation can be asynchronous
    console.log('DBProvider: firebase is done loading, and we have a userId, so load the rxdb')
    createDb().then(async (db) => {
      initializeDb(db).then(async () => {
        setDb(db)

        console.log('DBProvider: db is initialized, so now perform sync')
        await performSync(db, firestore, firebaseUserId)
        console.log('DBProvider: db is synced, so now we are done loading')
        setDbIsLoading(false)
      })
    })
  }, [firebaseAuthIsLoading, firebaseUserId, firestore])

  console.log(`DBProvider: rendering db=${db} dbIsLoading=${dbIsLoading}`)

  const value = useMemo(() => {
    return { db, dbIsLoading }
  }, [db, dbIsLoading])

  return <DBContext.Provider value={value}>{children}</DBContext.Provider>
}

export const useDBState = () => {
  return useContext(DBContext)
}

export const useDB = (): SiteOwlDatabase => {
  const db = useContext(DBContext).db
  if (!db) throw new Error('database not configured')
  return db
}

export const useAccountsCollection = (): RxCollection<Account> => {
  const db = useDB()
  return db.accounts
}

export const useUsersCollection = (): RxCollection<User> => {
  const db = useDB()
  return db.users
}

export const useOrgsCollection = (): RxCollection<Org> => {
  const db = useDB()
  return db.orgs
}

export const useOrgMembersCollection = (): RxCollection<OrgMember> => {
  const db = useDB()
  return db.org_members
}

export const useBuildingsCollection = (): RxCollection<Building> => {
  const db = useDB()
  return db.buildings
}

export const useAccountMembersCollection = (): RxCollection<AccountMember> => {
  const db = useDB()
  return db.account_members
}

export const useDeviceImagesCollection = (): RxCollection<DeviceImage> => {
  const db = useDB()
  return db.device_images
}

export const useDevicesCollection = (): RxCollection<Device> => {
  const db = useDB()
  return db.devices
}

export const useDeviceVersionsCollection = (): RxCollection<DeviceVersion> => {
  const db = useDB()
  return db.device_versions
}

export const useEquipmentTypeCategoriesCollection = (): RxCollection<EquipmentTypeCategory> => {
  const db = useDB()
  return db.equipment_type_categories
}

export const useEquipmentTypesCollection = (): RxCollection<EquipmentType> => {
  const db = useDB()
  return db.equipment_types
}

export const useFavoriteDevicesCollection = (): RxCollection<FavoriteDevice> => {
  const db = useDB()
  return db.favorite_devices
}

export const useFavoriteTasksCollection = (): RxCollection<FavoriteTask> => {
  const db = useDB()
  return db.favorite_tasks
}

export const useFavoritesCollection = (): RxCollection<Favorite> => {
  const db = useDB()
  return db.favorites
}

export const useFloorsCollection = (): RxCollection<Floor> => {
  const db = useDB()
  return db.floors
}

export const useLocationsCollection = (): RxCollection<Location> => {
  const db = useDB()
  return db.locations
}

export const useProjectFileAttachmentsCollection = (): RxCollection<ProjectFileAttachment> => {
  const db = useDB()
  return db.project_file_attachments
}

export const useProjectFloorsCollection = (): RxCollection<ProjectFloor> => {
  const db = useDB()
  return db.project_floors
}

export const useProjectMembersCollection = (): RxCollection<ProjectMember> => {
  const db = useDB()
  return db.project_members
}

export const useProjectsCollection = (): RxCollection<Project> => {
  const db = useDB()
  return db.projects
}

export const useServiceTicketCommentsCollection = (): RxCollection<ServiceTicketComment> => {
  const db = useDB()
  return db.service_ticket_comments
}

export const useServiceTicketFileAttachmentsCollection = (): RxCollection<ServiceTicketFileAttachment> => {
  const db = useDB()
  return db.service_ticket_file_attachments
}

export const useServiceTicketWatchersCollection = (): RxCollection<ServiceTicketWatcher> => {
  const db = useDB()
  return db.service_ticket_watchers
}

export const useServiceTicketsCollection = (): RxCollection<ServiceTicket> => {
  const db = useDB()
  return db.service_tickets
}

export const useSiteMembersCollection = (): RxCollection<SiteMember> => {
  const db = useDB()
  return db.site_members
}

export const useSitesCollection = (): RxCollection<Site> => {
  const db = useDB()
  return db.sites
}

export const useTaskImagesCollection = (): RxCollection<TaskImage> => {
  const db = useDB()
  return db.task_images
}

export const useTaskTypesCollection = (): RxCollection<TaskType> => {
  const db = useDB()
  return db.task_types
}

export const useTasksCollection = (): RxCollection<Task> => {
  const db = useDB()
  return db.tasks
}

export const useUserAccountPreferencesCollection = (): RxCollection<UserAccountPreferences> => {
  const db = useDB()
  return db.user_account_preferences
}

export const useUserDeviceTokensCollection = (): RxCollection<UserDeviceToken> => {
  const db = useDB()
  return db.user_device_tokens
}

export const useUserFloorPreferencesCollection = (): RxCollection<UserFloorPreferences> => {
  const db = useDB()
  return db.user_floor_preferences
}
