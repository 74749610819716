import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useTasksCollection } from '../DBProvider'
import { Floor } from '@/lib/schemas/FloorSchema'

export default function FloorTasksTab({ floor }: Readonly<{ floor: Floor }>) {
  const tasksCollection = useTasksCollection()

  const { result: tasks, isFetching } = useRxQuery(tasksCollection.find({ selector: { floorId: floor.id } }), {
    json: true,
  })

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Task</th>
          </tr>
        </thead>
        <tbody>
          {tasks?.map((task) => {
            return (
              <tr key={task.id}>
                <td>
                  <Link className="hover:text-primary" to={`tasks/${task.id}`}>
                    {task.id}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
