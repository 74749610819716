import { useParams } from 'react-router'
import OrgAccountsTab from './OrgAccountsTab'
import OrgMembersTab from './OrgMembersTab'
import OrgInfoTab from './OrgInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useCurrentOrg } from '../auth/UserProvider'
import { Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import TabPanel from '../TabPanel'
import { useOrgsCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info', 'accounts', 'members']

export default function OrgPage() {
  const orgsCollection = useOrgsCollection()
  const navigate = useNavigate()
  const { orgId, tabId = 'info' } = useParams()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)
  const currentOrg = useCurrentOrg()

  const { result: orgs, isFetching } = useRxQuery(orgsCollection.findOne(orgId), { json: true })

  if (isFetching) return <></>

  const org = orgs?.[0]
  if (!org) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath = newValue === 0 ? `/orgs/${orgId}` : `/orgs/${orgId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{org.name}</h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Accounts" />
          <Tab label="Members" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <OrgInfoTab org={org} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <OrgAccountsTab org={org} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <OrgMembersTab org={org} />
        </TabPanel>
      </Paper>
    </>
  )
}
