import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const serviceTicketCommentSchemaLiteral = {
  title: 'Service Ticket Comment Schema',
  describe: 'Describes a service ticket comment',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
    },
    accountId: {
      type: 'string',
    },
    serviceTicketId: {
      type: 'string',
    },
    comment: {
      type: 'string',
    },
    createdDate: {
      type: 'string',
      format: 'date-time',
    },
    createdByUserId: {
      type: 'string',
    },
    updatedDate: {
      type: 'string',
      format: 'date-time',
    },
    updatedByUserId: {
      type: 'string',
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'accountId', 'serviceTicketId', 'comment', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(serviceTicketCommentSchemaLiteral)

// aggregate the document type from the schema
export type ServiceTicketComment = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const serviceTicketCommentSchema: RxJsonSchema<ServiceTicketComment> = serviceTicketCommentSchemaLiteral

export type ServiceTicketCommentDocument = RxDocument<ServiceTicketComment>

export type ServiceTicketCommentCollection = RxCollection<ServiceTicketComment>
