import e from 'cors'
import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum DeviceActionType {
  install = 'install',
  remove = 'remove',
  move = 'move',
  document = 'document',
  live_edit = 'live_edit',
}

export enum DeviceDataType {
  analog = 'analog',
  ip = 'ip',
}

export enum DeviceLocation {
  interior = 'interior',
  exterior = 'exterior',
}

export enum DeviceOperationalStatus {
  not_working = 'not_working',
  operational = 'operational',
  operational_with_issue = 'operational_with_issue',
}

export enum DeviceInstallStatus {
  installed = 'installed',
  in_progress = 'in_progress',
  planned = 'planned',
}

export enum DeviceMonitoredStatus {
  offline = 'offline',
  online = 'online',
  device_not_found = 'device_not_found',
}

export enum DevicePriority {
  low = 'low',
  medium = 'medium',
  critical = 'critical',
}

export enum DeviceAutoUpdateStatus {
  by_monitoring_status = 'by_monitoring_status',
  dont_auto_update = 'dont_auto_update',
}

export enum DataSource {
  designowl = 'designowl',
  designowl_ios = 'designowl_ios',
  designowl_android = 'designowl_android',
  installowl = 'installowl',
  installowl_android = 'installowl_android',
  installowl_ios = 'installowl_ios',
  serviceowl_ios = 'serviceowl_ios',
  serviceowl_android = 'serviceowl_android',
  import = 'import',
  publish = 'publish',
  unknown = 'unknown',
  web = 'web',
  server = 'server',
}

const deviceVersionSchemaLiteral = {
  title: 'Device Version Schema',
  describe: 'Describes a device version',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
    },
    accountId: {
      type: 'string',
    },
    siteId: {
      type: 'string',
    },
    buildingId: {
      type: 'string',
    },
    floorId: {
      type: 'string',
    },
    locationId: {
      type: 'string',
    },
    deviceId: {
      type: 'string',
    },
    uniqueId: {
      type: 'string',
    },
    equipmentId: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    installHours: {
      type: 'number',
    },
    installedDate: {
      type: 'string',
      format: 'date-time',
    },
    createdDate: {
      type: 'string',
      format: 'date-time',
    },
    createdByUserId: {
      type: 'string',
    },
    updatedDate: {
      type: 'string',
      format: 'date-time',
    },
    updatedByUserId: {
      type: 'string',
    },
    installStatus: {
      type: 'string',
      enum: Object.values(DeviceInstallStatus),
    },
    installNotes: {
      type: 'string',
    },
    installedByUserId: {
      type: 'string',
    },
    dueDate: {
      type: 'string',
      format: 'date-time',
    },
    priority: {
      type: 'string',
      enum: Object.values(DevicePriority),
    },
    imagesCount: {
      type: 'number',
    },
    abbreviatedName: {
      type: 'string',
    },
    pinColor: {
      type: 'string',
      maxLength: 7,
    },
    isFlagged: {
      type: 'boolean',
    },
    flagNotes: {
      type: 'string',
    },
    flagCreatedByUserId: {
      type: 'string',
    },
    flagCreatedDate: {
      type: 'string',
      format: 'date-time',
    },
    createdSource: {
      type: 'string',
      enum: Object.values(DataSource),
    },
    updatedSource: {
      type: 'string',
      enum: Object.values(DataSource),
    },
    referenceDuplicateEquipmentId: {
      type: 'string',
    },
    assignedToUserId: {
      type: 'string',
    },
    projectId: {
      type: 'string',
    },
    serialNumber: {
      type: 'string',
    },
    partNumber: {
      type: 'string',
    },
    dataType: {
      type: 'string',
      enum: Object.values(DeviceDataType),
    },
    manufacturer: {
      type: 'string',
    },
    price: {
      type: 'number',
    },
    deviceLocation: {
      type: 'string',
      enum: Object.values(DeviceLocation),
    },
    ipAddress: {
      type: 'string',
    },
    macAddress: {
      type: 'string',
    },
    operationalStatus: {
      type: 'string',
      enum: Object.values(DeviceOperationalStatus),
    },
    barcode: {
      type: 'string',
    },
    height: {
      type: 'number',
    },
    deviceConnectedToDeviceId: {
      type: 'string',
    },
    coverageDirection: {
      type: 'number',
    },
    deviceWarrantyExpirationDate: {
      type: 'string',
      format: 'date-time',
    },
    laborWarrantyExpirationDate: {
      type: 'string',
      format: 'date-time',
    },
    isPublishedToAMT: {
      type: 'boolean',
    },
    monitoredStatus: {
      type: 'string',
      enum: Object.values(DeviceMonitoredStatus),
    },
    monitoredDate: {
      type: 'string',
      format: 'date-time',
    },
    monitoredDownDate: {
      type: 'string',
      format: 'date-time',
    },
    monitoredLiveFeed: {
      type: 'boolean',
    },
    monitoredDeviceId: {
      type: 'string',
    },
    autoUpdateStatus: {
      type: 'string',
      enum: Object.values(DeviceAutoUpdateStatus),
    },
    isSystemUpdate: {
      type: 'boolean',
    },
    coverageArea: {
      type: 'boolean',
    },
    coverageRange: {
      type: 'number',
    },
    networkSwitchLinkId: {
      type: 'number',
    },
    port: {
      type: 'string',
    },
    isHub: {
      type: 'boolean',
    },
    cableCoordinates: {
      type: 'string',
    },
    customDeviceId: {
      type: 'string',
    },
    connectionLength: {
      type: 'number',
    },
    flagResolvedByUserId: {
      type: 'string',
    },
    flagResolvedDate: {
      type: 'string',
      format: 'date-time',
    },
    budgetedCost: {
      type: 'number',
    },
    instructions: {
      type: 'string',
    },
    actionType: {
      type: 'string',
      enum: Object.values(DeviceActionType),
    },
    _deleted: { type: 'boolean' },
  },
  required: [
    'id',
    'orgId',
    'accountId',
    'deviceId',
    'uniqueId',
    'equipmentId',
    'name',
    'installStatus',
    'priority',
    'imagesCount',
    'pinColor',
    'isFlagged',
    'createdSource',
    'updatedSource',
    'actionType',
    'autoUpdateStatus',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(deviceVersionSchemaLiteral)

// aggregate the document type from the schema
export type DeviceVersion = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const deviceVersionSchema: RxJsonSchema<DeviceVersion> = deviceVersionSchemaLiteral

export type DeviceVersionDocument = RxDocument<DeviceVersion>

export type DeviceVersionCollection = RxCollection<DeviceVersion>
