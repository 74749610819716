import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum PinColorPreference {
  device_color = 'device_color',
  device_status = 'device_status',
  operational_status = 'operational_status',
  install_status = 'install_status',
}

const userFloorPreferencesSchemaLiteral = {
  title: 'User Floor Preferences Schema',
  describe: 'Describes user preferences for a floor',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    userId: {
      type: 'string',
    },
    floorId: {
      type: 'string',
    },
    showDeviceSize: {
      type: 'number',
    },
    hideLiveDevice: {
      type: 'boolean',
    },
    pinColorPreference: {
      type: 'string',
      enum: Object.values(PinColorPreference),
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'userId', 'floorId', 'showDeviceSize', 'hideLiveDevice', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(userFloorPreferencesSchemaLiteral)

// aggregate the document type from the schema
export type UserFloorPreferences = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const userFloorPreferencesSchema: RxJsonSchema<UserFloorPreferences> = userFloorPreferencesSchemaLiteral

export type UserFloorPreferencesDocument = RxDocument<UserFloorPreferences>

export type UserFloorPreferencesCollection = RxCollection<UserFloorPreferences>
