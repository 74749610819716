import { useUser } from '@/components/auth/UserProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useUserAccountPreferencesCollection } from '../DBProvider'
import { Account } from '@/lib/schemas/AccountSchema'

export default function AccountPreferencesTab({ account }: Readonly<{ account: Account }>) {
  const user = useUser()
  const userAccountPreferencesCollection = useUserAccountPreferencesCollection()

  const { result: userAccountPreferencesArray, isFetching } = useRxQuery(
    userAccountPreferencesCollection.find({ selector: { userId: user.id, accountId: account.id } }),
    {
      json: true,
    },
  )
  const userAccountPreferences = userAccountPreferencesArray?.[0]
  if (isFetching || !userAccountPreferences) return <></>

  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="border text-right bg-muted p-3">userId</td>
          <td className="border p-3">{userAccountPreferences.userId}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">amtDeviceField</td>
          <td className="border p-3">{userAccountPreferences.amtDeviceField}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">projectDeviceField</td>
          <td className="border p-3">{userAccountPreferences.projectDeviceField}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">serviceTicketField</td>
          <td className="border p-3">{userAccountPreferences.serviceTicketField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">projectDevicePunchListField</td>
          <td className="rounded-br-lg border p-3">{userAccountPreferences.projectDevicePunchListField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">isProjectDevicePunchList</td>
          <td className="rounded-br-lg border p-3">{userAccountPreferences.isProjectDevicePunchList}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">amtDevicePhotoReviewField</td>
          <td className="rounded-br-lg border p-3">{userAccountPreferences.amtDevicePhotoReviewField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">amtFieldView</td>
          <td className="rounded-br-lg border p-3">{userAccountPreferences.amtFieldView}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">projectFieldView</td>
          <td className="rounded-br-lg border p-3">{userAccountPreferences.projectFieldView}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">projectDeviceCompletedWorkField</td>
          <td className="rounded-br-lg border p-3">{userAccountPreferences.projectDeviceCompletedWorkField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">projectDeviceFlagReviewField</td>
          <td className="rounded-br-lg border p-3">{userAccountPreferences.projectDeviceFlagReviewField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">projectReportField</td>
          <td className="rounded-br-lg border p-3">{userAccountPreferences.projectReportField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">livesiteReportField</td>
          <td className="rounded-br-lg border p-3">{userAccountPreferences.livesiteReportField}</td>
        </tr>
      </tbody>
    </table>
  )
}
