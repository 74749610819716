import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import AccountSitesTab from './AccountSitesTab'
import AccountMembersTab from './AccountMembersTab'
import AccountInfoTab from './AccountInfoTab'
import AccountFavoritesTab from './AccountFavoritesTab'
import AccountPreferencesTab from './AccountPreferencesTab'
import AccountProjectsTab from './AccountProjectsTab'
import AccountServiceTicketsTab from './AccountServiceTicketsTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useCurrentOrg } from '../auth/UserProvider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useState, useEffect } from 'react'
import TabPanel from '../TabPanel'
import { useRxQuery } from 'rxdb-hooks'
import { useAccountsCollection } from '../DBProvider'

const tabValues = ['info', 'sites', 'projects', 'service-tickets', 'favorites', 'preferences', 'members']

export default function AccountPage() {
  const { accountId, tabId = 'info' } = useParams()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)
  const accountsCollection = useAccountsCollection()
  const { result: accounts, isFetching } = useRxQuery(accountsCollection.findOne(accountId), { json: true })

  useEffect(() => {
    setTabValue(tabValues.indexOf(tabId))
  }, [tabId])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath = newValue === 0 ? `/accounts/${accountId}` : `/accounts/${accountId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  if (isFetching) return <></>

  const account = accounts?.[0]
  if (!account) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{account.name}</Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Sites" />
          <Tab label="Projects" />
          <Tab label="Service Tickets" />
          <Tab label="Favorites" />
          <Tab label="Preferences" />
          <Tab label="Members" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <AccountInfoTab account={account} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <AccountSitesTab account={account} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <AccountProjectsTab account={account} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <AccountServiceTicketsTab account={account} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <AccountFavoritesTab account={account} />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <AccountPreferencesTab account={account} />
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <AccountMembersTab account={account} />
        </TabPanel>
      </Paper>
    </>
  )
}
