import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const projectFloorSchemaLiteral = {
  title: 'Project Floor Schema',
  describe: 'Describes a project floor',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
    },
    accountId: {
      type: 'string',
    },
    projectId: {
      type: 'string',
    },
    floorId: {
      type: 'string',
    },
    planImageName: {
      type: 'string',
    },
    planImagePath: {
      type: 'string',
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'accountId', 'projectId', 'floorId', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(projectFloorSchemaLiteral)

// aggregate the document type from the schema
export type ProjectFloor = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const projectFloorSchema: RxJsonSchema<ProjectFloor> = projectFloorSchemaLiteral

export type ProjectFloorDocument = RxDocument<ProjectFloor>

export type ProjectFloorCollection = RxCollection<ProjectFloor>
