import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const floorSchemaLiteral = {
  title: 'Floor Schema',
  describe: 'Describes a floor',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: { type: 'string', maxLength: 36 },
    orgId: { type: 'string' },
    accountId: { type: 'string' },
    siteId: { type: 'string' },
    buildingId: { type: 'string' },
    name: { type: 'string' },
    planImageFileName: { type: 'string' },
    planImagePath: { type: 'string' },
    orderNumber: { type: 'number' },
    width: { type: 'number' },
    height: { type: 'number' },
    isArchived: { type: 'boolean' },
    createdDate: { type: 'string', format: 'date-time' },
    createdByUserId: { type: 'string' },
    updatedDate: { type: 'string', format: 'date-time' },
    updatedByUserId: { type: 'string' },
    planImageUpdatedDate: { type: 'string', format: 'date-time' },
    planImageUpdatedByUserId: { type: 'string' },
    floorScale: { type: 'number' },
    _deleted: { type: 'boolean' },
  },
  required: [
    'id',
    'orgId',
    'accountId',
    'siteId',
    'buildingId',
    'name',
    'planImagePath',
    'orderNumber',
    'isArchived',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(floorSchemaLiteral)

// aggregate the document type from the schema
export type Floor = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const floorSchema: RxJsonSchema<Floor> = floorSchemaLiteral

export type FloorDocument = RxDocument<Floor>

export type FloorCollection = RxCollection<Floor>
