import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const siteMemberSchemaLiteral = {
  title: 'Site Member Schema',
  describe: 'Describes a site member',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: { type: 'string', maxLength: 36 },
    orgId: { type: 'string' },
    accountId: { type: 'string' },
    siteId: { type: 'string' },
    userId: { type: 'string' },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'accountId', 'siteId', 'userId', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(siteMemberSchemaLiteral)

// aggregate the document type from the schema
export type SiteMember = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const siteMemberSchema: RxJsonSchema<SiteMember> = siteMemberSchemaLiteral

export type SiteMemberDocument = RxDocument<SiteMember>

export type SiteMemberCollection = RxCollection<SiteMember>
