import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum PlanType {
  trial = 'trial',
  internal = 'internal',
  licensed = 'licensed',
  unlicensed = 'unlicensed',
  non_billable = 'non_billable',
}

const orgSchemaLiteral = {
  title: 'Org Schema',
  describe: 'Describes an organization',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    name: {
      type: 'string',
    },
    email: {
      type: 'string',
    },
    phoneNumber: {
      type: 'string',
    },
    planType: {
      type: 'string',
      enum: Object.values(PlanType),
    },
    logoImagePath: {
      type: 'string',
    },
    fronteggId: {
      type: 'string',
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'name', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(orgSchemaLiteral)

// aggregate the document type from the schema
export type Org = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const orgSchema: RxJsonSchema<Org> = orgSchemaLiteral

export type OrgDocument = RxDocument<Org>

export type OrgCollection = RxCollection<Org>
