import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useProjectFloorsCollection } from '../DBProvider'
import { Project } from '@/lib/schemas/ProjectSchema'

export default function ProjectFloorsTab({ project }: Readonly<{ project: Project }>) {
  const projectFloorsCollection = useProjectFloorsCollection()

  const { result: projectFloors, isFetching } = useRxQuery(
    projectFloorsCollection.find({ selector: { projectId: project.id } }),
    {
      json: true,
    },
  )

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Floor</th>
          </tr>
        </thead>
        <tbody>
          {projectFloors?.map((projectFloor) => {
            return (
              <tr key={projectFloor.floorId}>
                <td>
                  <Link className="hover:text-primary" to={`floors/${projectFloor.floorId}`}>
                    {projectFloor.floorId}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
