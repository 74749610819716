import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'
import { DeviceLocation } from './DeviceVersionSchema'

const favoriteDeviceSchemaLiteral = {
  title: 'Favorite Device Schema',
  describe: 'Describes a favorite device',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
    },
    accountId: {
      type: 'string',
    },
    favoriteId: {
      type: 'string',
    },
    equipmentTypeId: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    installHours: {
      type: 'number',
    },
    orderNumber: {
      type: 'number',
    },
    pinColor: {
      type: 'string',
    },
    abbreviatedName: {
      type: 'string',
    },
    partNumber: {
      type: 'string',
    },
    dataType: {
      type: 'string',
    },
    manufacturer: {
      type: 'string',
    },
    price: {
      type: 'number',
    },
    deviceLocation: {
      type: 'string',
      enum: Object.values(DeviceLocation),
    },
    budgetedCost: {
      type: 'number',
    },
    _deleted: { type: 'boolean' },
  },
  required: [
    'id',
    'orgId',
    'accountId',
    'favoriteId',
    'equipmentTypeId',
    'name',
    'orderNumber',
    'pinColor',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(favoriteDeviceSchemaLiteral)

// aggregate the document type from the schema
export type FavoriteDevice = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const favoriteDeviceSchema: RxJsonSchema<FavoriteDevice> = favoriteDeviceSchemaLiteral

export type FavoriteDeviceDocument = RxDocument<FavoriteDevice>

export type FavoriteDeviceCollection = RxCollection<FavoriteDevice>
