import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const projectFileAttachmentSchemaLiteral = {
  title: 'Project File Attachment Schema',
  describe: 'Describes a project file attachment',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
    },
    accountId: {
      type: 'string',
    },
    projectId: {
      type: 'string',
    },
    fileName: {
      type: 'string',
    },
    fileType: {
      type: 'string',
    },
    filePath: {
      type: 'string',
    },
    fileSize: {
      type: 'number',
    },
    uploadedByUserId: {
      type: 'string',
    },
    uploadedDate: {
      type: 'string',
      format: 'date-time',
    },
    _deleted: { type: 'boolean' },
  },
  required: [
    'id',
    'orgId',
    'accountId',
    'projectId',
    'fileName',
    'fileType',
    'filePath',
    'fileSize',
    'uploadedDate',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(projectFileAttachmentSchemaLiteral)

// aggregate the document type from the schema
export type ProjectFileAttachment = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const projectFileAttachmentSchema: RxJsonSchema<ProjectFileAttachment> = projectFileAttachmentSchemaLiteral

export type ProjectFileAttachmentDocument = RxDocument<ProjectFileAttachment>

export type ProjectFileAttachmentCollection = RxCollection<ProjectFileAttachment>
