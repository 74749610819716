import { addRxPlugin, createRxDatabase, RxDatabase } from 'rxdb'
import { disableWarnings, RxDBDevModePlugin } from 'rxdb/plugins/dev-mode'
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie'
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder'
import { replicateFirestore } from 'rxdb/plugins/replication-firestore'
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election'

import { BuildingCollection, buildingSchema } from './schemas/BuildingSchema'
import { AccountCollection, accountSchema } from './schemas/AccountSchema'
import { AccountMemberCollection, accountMemberSchema } from './schemas/AccountMemberSchema'
import { DeviceCollection, deviceSchema } from './schemas/DeviceSchema'
import { DeviceImageCollection, deviceImageSchema } from './schemas/DeviceImageSchema'
import { DeviceVersionCollection, deviceVersionSchema } from './schemas/DeviceVersionSchema'
import { EquipmentTypeCollection, equipmentTypeSchema } from './schemas/EquipmentTypeSchema'
import { EquipmentTypeCategoryCollection, equipmentTypeCategorySchema } from './schemas/EquipmentTypeCategorySchema'
import { FavoriteCollection, favoriteSchema } from './schemas/FavoriteSchema'
import { FavoriteDeviceCollection, favoriteDeviceSchema } from './schemas/FavoriteDeviceSchema'
import { FavoriteTaskCollection, favoriteTaskSchema } from './schemas/FavoriteTaskSchema'
import { FloorCollection, floorSchema } from './schemas/FloorSchema'
import { LocationCollection, locationSchema } from './schemas/LocationSchema'
import { OrgCollection, orgSchema } from './schemas/OrgSchema'
import { OrgMemberCollection, orgMemberSchema } from './schemas/OrgMemberSchema'
import { ProjectCollection, projectSchema } from './schemas/ProjectSchema'
import { ProjectFileAttachmentCollection, projectFileAttachmentSchema } from './schemas/ProjectFileAttachmentSchema'
import { ProjectFloorCollection, projectFloorSchema } from './schemas/ProjectFloorSchema'
import { ProjectMemberCollection, projectMemberSchema } from './schemas/ProjectMemberSchema'
import { ServiceTicketCollection, serviceTicketSchema } from './schemas/ServiceTicketSchema'
import { ServiceTicketCommentCollection, serviceTicketCommentSchema } from './schemas/ServiceTicketCommentSchema'
import {
  ServiceTicketFileAttachmentCollection,
  serviceTicketFileAttachmentSchema,
} from './schemas/ServiceTicketFileAttachmentSchema'
import { ServiceTicketWatcherCollection, serviceTicketWatcherSchema } from './schemas/ServiceTicketWatcherSchema'
import { SiteCollection, siteSchema } from './schemas/SiteSchema'
import { SiteMemberCollection, siteMemberSchema } from './schemas/SiteMemberSchema'
import { TaskCollection, taskSchema } from './schemas/TaskSchema'
import { TaskImageCollection, taskImageSchema } from './schemas/TaskImageSchema'
import { TaskTypeCollection, taskTypeSchema } from './schemas/TaskTypeSchema'
import { UserCollection, userSchema } from './schemas/UserSchema'
import { UserAccountPreferencesCollection, userAccountPreferencesSchema } from './schemas/UserAccountPreferencesSchema'
import { UserDeviceTokenCollection, userDeviceTokenSchema } from './schemas/UserDeviceTokenSchema'
import { UserFloorPreferencesCollection, userFloorPreferencesSchema } from './schemas/UserFloorPreferencesSchema'
import { collection, Firestore, where } from 'firebase/firestore'

// don't show the dev mode warning in dev because we expect them
if (process.env.NODE_ENV === 'development') {
  disableWarnings()
  addRxPlugin(RxDBDevModePlugin)
}

addRxPlugin(RxDBLeaderElectionPlugin)
addRxPlugin(RxDBQueryBuilderPlugin)

export type SiteOwlCollections = {
  buildings: BuildingCollection
  account_members: AccountMemberCollection
  accounts: AccountCollection
  device_images: DeviceImageCollection
  devices: DeviceCollection
  device_versions: DeviceVersionCollection
  equipment_type_categories: EquipmentTypeCategoryCollection
  equipment_types: EquipmentTypeCollection
  favorite_devices: FavoriteDeviceCollection
  favorite_tasks: FavoriteTaskCollection
  favorites: FavoriteCollection
  floors: FloorCollection
  locations: LocationCollection
  org_members: OrgMemberCollection
  orgs: OrgCollection
  project_file_attachments: ProjectFileAttachmentCollection
  project_floors: ProjectFloorCollection
  project_members: ProjectMemberCollection
  projects: ProjectCollection
  service_ticket_comments: ServiceTicketCommentCollection
  service_ticket_file_attachments: ServiceTicketFileAttachmentCollection
  service_ticket_watchers: ServiceTicketWatcherCollection
  service_tickets: ServiceTicketCollection
  site_members: SiteMemberCollection
  sites: SiteCollection
  task_images: TaskImageCollection
  task_types: TaskTypeCollection
  tasks: TaskCollection
  users: UserCollection
  user_account_preferences: UserAccountPreferencesCollection
  user_device_tokens: UserDeviceTokenCollection
  user_floor_preferences: UserFloorPreferencesCollection
}

export type SiteOwlDatabase = RxDatabase<SiteOwlCollections>

export async function createDb(): Promise<SiteOwlDatabase> {
  const db: SiteOwlDatabase = await createRxDatabase<SiteOwlCollections>({
    name: 'siteowlrxdb',
    storage: getRxStorageDexie(),
    multiInstance: true,
    eventReduce: true,
    ignoreDuplicate: process.env.NODE_ENV === 'development',
  })
  return db
}

export async function initializeDb(db: SiteOwlDatabase) {
  await db.addCollections({
    buildings: {
      schema: buildingSchema,
    },
    account_members: {
      schema: accountMemberSchema,
    },
    accounts: {
      schema: accountSchema,
    },
    device_images: {
      schema: deviceImageSchema,
    },
    devices: {
      schema: deviceSchema,
    },
    device_versions: {
      schema: deviceVersionSchema,
    },
    equipment_type_categories: {
      schema: equipmentTypeCategorySchema,
    },
    equipment_types: {
      schema: equipmentTypeSchema,
    },
    favorite_devices: {
      schema: favoriteDeviceSchema,
    },
    favorite_tasks: {
      schema: favoriteTaskSchema,
    },
    favorites: {
      schema: favoriteSchema,
    },
    floors: {
      schema: floorSchema,
    },
    locations: {
      schema: locationSchema,
    },
    org_members: {
      schema: orgMemberSchema,
    },
    orgs: {
      schema: orgSchema,
    },
    project_file_attachments: {
      schema: projectFileAttachmentSchema,
    },
    project_floors: {
      schema: projectFloorSchema,
    },
    project_members: {
      schema: projectMemberSchema,
    },
    projects: {
      schema: projectSchema,
    },
    service_ticket_comments: {
      schema: serviceTicketCommentSchema,
    },
    service_ticket_file_attachments: {
      schema: serviceTicketFileAttachmentSchema,
    },
    service_ticket_watchers: {
      schema: serviceTicketWatcherSchema,
    },
    service_tickets: {
      schema: serviceTicketSchema,
    },
    site_members: {
      schema: siteMemberSchema,
    },
    sites: {
      schema: siteSchema,
    },
    task_images: {
      schema: taskImageSchema,
    },
    task_types: {
      schema: taskTypeSchema,
    },
    tasks: {
      schema: taskSchema,
    },
    users: {
      schema: userSchema,
    },
    user_account_preferences: {
      schema: userAccountPreferencesSchema,
    },
    user_device_tokens: {
      schema: userDeviceTokenSchema,
    },
    user_floor_preferences: {
      schema: userFloorPreferencesSchema,
    },
  })
}

export async function performSync(db: SiteOwlDatabase, firestore: Firestore, currentUserId: string) {
  console.log('starting replicateFirestore', import.meta.env.VITE_FIREBASE_PROJECT_ID)
  const userReplicationState = replicateFirestore({
    replicationIdentifier: `https://firestore.googleapis.com/${import.meta.env.VITE_FIREBASE_PROJECT_ID}`,
    collection: db.users,
    firestore: {
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      database: firestore,
      collection: collection(firestore, 'users'),
    },
    push: {}, // an object is required here to enabled push
    pull: {
      // filter: [where('id', '==', currentUserId)],
    }, // an object is required here to enabled pull
    live: true,
    waitForLeadership: true,
    autoStart: true,
    serverTimestampField: 'serverTimestamp',
  })

  // emits each document that was received from the remote
  userReplicationState.received$.subscribe((doc) => console.log('received', doc))

  // emits each document that was send to the remote
  userReplicationState.sent$.subscribe((doc) => console.log('sent', doc))

  // emits all errors that happen when running the push- & pull-handlers.
  userReplicationState.error$.subscribe((error) => console.log('error', error))

  // emits true when the replication was canceled, false when not.
  // userReplicationState.canceled$.subscribe((bool) => console.log('canceled', bool))

  // emits true when a replication cycle is running, false when not.
  // userReplicationState.active$.subscribe((bool) => console.log('active', bool))

  console.log('awaitInSync')
  await userReplicationState.awaitInSync()

  const orgReplicationState = replicateFirestore({
    replicationIdentifier: `https://firestore.googleapis.com/${import.meta.env.VITE_FIREBASE_PROJECT_ID}`,
    collection: db.orgs,
    firestore: {
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      database: firestore,
      collection: collection(firestore, 'orgs'),
    },
    push: {}, // an object is required here to enabled push
    pull: {
      // filter: [where('id', '==', currentUserId)],
    }, // an object is required here to enabled pull
    live: true,
    waitForLeadership: true,
    autoStart: true,
    serverTimestampField: 'serverTimestamp',
  })

  // emits each document that was received from the remote
  orgReplicationState.received$.subscribe((doc) => console.log('received', doc))

  // emits each document that was send to the remote
  orgReplicationState.sent$.subscribe((doc) => console.log('sent', doc))

  // emits all errors that happen when running the push- & pull-handlers.
  orgReplicationState.error$.subscribe((error) => console.log('error', error))

  // emits true when the replication was canceled, false when not.
  orgReplicationState.canceled$.subscribe((bool) => console.log('canceled', bool))

  // emits true when a replication cycle is running, false when not.
  orgReplicationState.active$.subscribe((bool) => console.log('active', bool))

  const accountReplicationState = replicateFirestore({
    replicationIdentifier: `https://firestore.googleapis.com/${import.meta.env.VITE_FIREBASE_PROJECT_ID}`,
    collection: db.accounts,
    firestore: {
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      database: firestore,
      collection: collection(firestore, 'accounts'),
    },
    push: {}, // an object is required here to enabled push
    pull: {
      // filter: [where('id', '==', currentUserId)],
    }, // an object is required here to enabled pull
    live: true,
    waitForLeadership: true,
    autoStart: true,
    serverTimestampField: 'serverTimestamp',
  })

  // emits each document that was received from the remote
  accountReplicationState.received$.subscribe((doc) => console.log('received', doc))

  // emits each document that was send to the remote
  accountReplicationState.sent$.subscribe((doc) => console.log('sent', doc))

  // emits all errors that happen when running the push- & pull-handlers.
  accountReplicationState.error$.subscribe((error) => console.log('error', error))

  // emits true when the replication was canceled, false when not.
  accountReplicationState.canceled$.subscribe((bool) => console.log('canceled', bool))

  // emits true when a replication cycle is running, false when not.
  accountReplicationState.active$.subscribe((bool) => console.log('active', bool))

  console.log('awaitInSync')
  await userReplicationState.awaitInSync()
  await orgReplicationState.awaitInSync()
}
