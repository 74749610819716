import { useParams } from 'react-router'
import ServiceTicketWatchersTab from './ServiceTicketWatchersTab'
import ServiceTicketInfoTab from './ServiceTicketInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import ServiceTicketCommentsTab from './ServiceTicketCommentsTab'
import ServiceTicketFileAttachmentsTab from './ServiceTicketFilesTab'
import { useCurrentOrg } from '../auth/UserProvider'
import { Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import TabPanel from '../TabPanel'
import { useServiceTicketsCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info', 'watchers', 'comments', 'files']

export default function ServiceTicketPage() {
  const serviceTicketsCollection = useServiceTicketsCollection()
  const navigate = useNavigate()
  const { serviceTicketId, tabId = 'info' } = useParams()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)
  const currentOrg = useCurrentOrg()

  const { result: serviceTickets, isFetching } = useRxQuery(serviceTicketsCollection.findOne(serviceTicketId), {
    json: true,
  })

  if (isFetching) return <></>

  const serviceTicket = serviceTickets?.[0]
  if (!serviceTicket) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath =
      newValue === 0
        ? `/service-tickets/${serviceTicketId}`
        : `/service-tickets/${serviceTicketId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{serviceTicket.subject}</h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Watchers" />
          <Tab label="Comments" />
          <Tab label="Files" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <ServiceTicketInfoTab serviceTicket={serviceTicket} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ServiceTicketWatchersTab serviceTicket={serviceTicket} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ServiceTicketCommentsTab serviceTicket={serviceTicket} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <ServiceTicketFileAttachmentsTab serviceTicket={serviceTicket} />
        </TabPanel>
      </Paper>
    </>
  )
}
