import { Link } from 'react-router'
import { useFavoritesCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { Account } from '@/lib/schemas/AccountSchema'

export default function AccountFavoriteDevicesTab({ account }: Readonly<{ account: Account }>) {
  const favoritesCollection = useFavoritesCollection()

  const { result: favorites, isFetching } = useRxQuery(
    favoritesCollection.find({ selector: { accountId: account.id } }),
    {
      json: true,
    },
  )

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Favorite Device</th>
          </tr>
        </thead>
        <tbody>
          {favorites?.map((favorite) => {
            return (
              <tr key={favorite.id}>
                <td>
                  <Link className="hover:text-primary" to={`favorites/${favorite.id}`}>
                    {favorite.name}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
