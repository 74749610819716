import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useLocationsCollection } from '../DBProvider'
import { Floor } from '@/lib/schemas/FloorSchema'

export default function FloorLocationsTab({ floor }: Readonly<{ floor: Floor }>) {
  const locationsCollection = useLocationsCollection()

  const { result: locations, isFetching } = useRxQuery(locationsCollection.find({ selector: { floorId: floor.id } }), {
    json: true,
  })

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Device</th>
          </tr>
        </thead>
        <tbody>
          {locations?.map((location) => {
            return (
              <tr key={location.id}>
                <td>
                  <Link className="hover:text-primary" to={`locations/${location.id}`}>
                    {location.name || location.imageLeft + '/' + location.imageTop}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
