import { useParams } from 'react-router'
import SiteBuildingsTab from './SiteBuildingsTab'
import SiteMembersTab from './SiteMembersTab'
import SiteInfoTab from './SiteInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useCurrentOrg } from '../auth/UserProvider'
import { Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import TabPanel from '../TabPanel'
import { useSitesCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { Site } from '@/lib/schemas/SiteSchema'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info', 'buildings', 'members']

export default function SitePage() {
  const sitesCollection = useSitesCollection()
  const navigate = useNavigate()
  const { siteId, tabId = 'info' } = useParams()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)
  const currentOrg = useCurrentOrg()

  const { result: sites, isFetching } = useRxQuery(sitesCollection.findOne(siteId), { json: true })

  if (isFetching) return <></>

  const site = sites?.[0]
  if (!site) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath = newValue === 0 ? `/sites/${siteId}` : `/sites/${siteId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{site.name}</h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Buildings" />
          <Tab label="Members" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <SiteInfoTab site={site} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <SiteBuildingsTab site={site} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <SiteMembersTab site={site} />
        </TabPanel>
      </Paper>
    </>
  )
}
