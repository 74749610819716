import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const equipmentTypeSchemaLiteral = {
  title: 'Equipment Type Schema',
  describe: 'Describes an equipment type',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    name: {
      type: 'string',
    },
    iconName: {
      type: 'string',
    },
    category: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        order: {
          type: 'number',
        },
        pinColor: {
          type: 'string',
        },
      },
      required: ['id', 'name', 'order'],
      additionalProperties: false,
    },
    iconUnicode: {
      type: 'string',
    },
    abbreviatedNamePrefix: {
      type: 'string',
    },
    isHub: {
      type: 'boolean',
    },
    isSmart: {
      type: 'boolean',
    },
    isCoverage: {
      type: 'boolean',
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'name', 'iconName', 'category', 'iconUnicode', 'isHub', 'isSmart', 'isCoverage', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(equipmentTypeSchemaLiteral)

// aggregate the document type from the schema
export type EquipmentType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const equipmentTypeSchema: RxJsonSchema<EquipmentType> = equipmentTypeSchemaLiteral

export type EquipmentTypeDocument = RxDocument<EquipmentType>

export type EquipmentTypeCollection = RxCollection<EquipmentType>
