import { useParams } from 'react-router'
import LocationInfoTab from './LocationInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useCurrentOrg } from '../auth/UserProvider'
import { Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import TabPanel from '../TabPanel'
import { useLocationsCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info']

export default function LocationPage() {
  const { locationId, tabId = 'info' } = useParams()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)
  const locationsCollection = useLocationsCollection()
  const currentOrg = useCurrentOrg()

  const { result: locations, isFetching } = useRxQuery(locationsCollection.findOne(locationId), { json: true })

  if (isFetching) return <></>

  const location = locations?.[0]
  if (!location) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath = newValue === 0 ? `/locations/${locationId}` : `/locations/${locationId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">
        {location.imageLeft} x {location.imageTop}
      </h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <LocationInfoTab location={location} />
        </TabPanel>
      </Paper>
    </>
  )
}
