import { useParams } from 'react-router'
import FavoriteInfoTab from './FavoriteInfoTab'
import FavoriteFavoriteDevicesTab from './FavoriteDevicesTab'
import FavoriteFavoriteTasksTab from './FavoriteTasksTab'
import NotFoundPage from '@/components/NotFoundPage'
import { Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import TabPanel from '../TabPanel'
import { useFavoritesCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info', 'devices', 'tasks']

export default function FavoritePage() {
  const { favoriteId, tabId = 'info' } = useParams()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)
  const favoritesCollection = useFavoritesCollection()

  const { result: favorites, isFetching } = useRxQuery(favoritesCollection.findOne(favoriteId), { json: true })

  if (isFetching) return <></>

  const favorite = favorites?.[0]
  if (!favorite) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath = newValue === 0 ? `/favorites/${favoriteId}` : `/favorites/${favoriteId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{favorite.name}</h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Devices" />
          <Tab label="Tasks" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <FavoriteInfoTab favorite={favorite} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <FavoriteFavoriteDevicesTab favorite={favorite} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <FavoriteFavoriteTasksTab favorite={favorite} />
        </TabPanel>
      </Paper>
    </>
  )
}
