import { useParams } from 'react-router'
import FavoriteDeviceInfoTab from './FavoriteDeviceInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useCurrentOrg } from '../auth/UserProvider'
import { Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import TabPanel from '../TabPanel'
import { useFavoriteDevicesCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info']

export default function FavoriteDevicePage() {
  const { favoriteDeviceId, tabId = 'info' } = useParams()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)
  const favoriteDevicesCollection = useFavoriteDevicesCollection()
  const { result: favoriteDevices, isFetching } = useRxQuery(favoriteDevicesCollection.findOne(favoriteDeviceId), {
    json: true,
  })

  if (isFetching) return <></>

  const favoriteDevice = favoriteDevices?.[0]
  if (!favoriteDevice) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath =
      newValue === 0
        ? `/favorite-devices/${favoriteDeviceId}`
        : `/favorite-devices/${favoriteDeviceId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{favoriteDevice.name}</h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <FavoriteDeviceInfoTab favoriteDevice={favoriteDevice} />
        </TabPanel>
      </Paper>
    </>
  )
}
