import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useServiceTicketsCollection } from '../DBProvider'
import { Account } from '@/lib/schemas/AccountSchema'

export default function AccountServiceTicketsTab({ account }: Readonly<{ account: Account }>) {
  const serviceTicketsCollection = useServiceTicketsCollection()

  const { result: serviceTickets, isFetching } = useRxQuery(
    serviceTicketsCollection.find({ selector: { accountId: account.id } }),
    {
      json: true,
    },
  )

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Service Ticket</th>
          </tr>
        </thead>
        <tbody>
          {serviceTickets?.map((serviceTicket) => {
            return (
              <tr key={serviceTicket.id}>
                <td>
                  <Link className="hover:text-primary" to={`service-tickets/${serviceTicket.id}`}>
                    {serviceTicket.subject}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
