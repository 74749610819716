import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const userDeviceTokenSchemaLiteral = {
  title: 'User Device Token Schema',
  describe: 'Describes a user device token',
  version: 0,
  primaryKey: 'userId',
  type: 'object',
  properties: {
    userId: {
      type: 'string',
      maxLength: 36,
    },
    deviceToken: {
      type: 'string',
    },
    _deleted: { type: 'boolean' },
  },
  required: ['userId', 'deviceToken', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(userDeviceTokenSchemaLiteral)

// aggregate the document type from the schema
export type UserDeviceToken = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const userDeviceTokenSchema: RxJsonSchema<UserDeviceToken> = userDeviceTokenSchemaLiteral

export type UserDeviceTokenDocument = RxDocument<UserDeviceToken>

export type UserDeviceTokenCollection = RxCollection<UserDeviceToken>
