import { Site } from '@/lib/schemas/SiteSchema'

export default function SiteInfoTab({ site }: { site: Site }) {
  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Name</td>
          <td className="rounded-tr-lg border p-3">{site.name}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Address</td>
          <td className="border p-3">{site.address}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">City</td>
          <td className="border p-3">{site.city}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">State</td>
          <td className="border p-3">{site.state}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Zip Code</td>
          <td className="rounded-br-lg border p-3">{site.zip}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Notes</td>
          <td className="rounded-br-lg border p-3">{site.notes}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Is Archived</td>
          <td className="rounded-br-lg border p-3">{site.isArchived}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Default Ticket Assigned To</td>
          <td className="rounded-br-lg border p-3">{site.defaultTicketAssignedToUserId}</td>
        </tr>
      </tbody>
    </table>
  )
}
