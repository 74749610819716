import * as React from 'react'
import AppBar, { AppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import { CSSObject, Theme, alpha, styled } from '@mui/material/styles'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ProfileMenu from './ProfileMenu'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faPenRuler,
  faWrench,
  faChartColumn,
  faPeopleCarryBox,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { Outlet, useLocation } from 'react-router'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: `calc(${drawerWidth}px + var(--safe-area-left))`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px + var(--safe-area-left))`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px + var(--safe-area-left))`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface MiniAppBarProps extends AppBarProps {
  open?: boolean
}

const MiniAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<MiniAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `calc(${drawerWidth}px + var(--safe-area-left))`,
    width: `calc(100% - ${drawerWidth}px - var(--safe-area-left))`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const MiniDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))

export default function ResponsiveDrawer() {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [isClosing, setIsClosing] = React.useState(false)

  const handleDrawerOpen = () => {
    setMobileOpen(true)
  }

  const handleDrawerClose = () => {
    setMobileOpen(false)
  }

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false)
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen)
    }
  }

  const drawer = (
    <Box
      sx={{
        paddingTop: 'var(--safe-area-top)',
        paddingLeft: 'var(--safe-area-left)',
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerToggle} sx={{ display: { xs: 'none', sm: mobileOpen ? 'block' : 'none' } }}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <NavItem label="Accounts" icon={faUsers} mobileOpen={mobileOpen} href="/accounts" onClick={handleDrawerClose} />
        <NavItem
          label="Design Projects"
          icon={faPenRuler}
          mobileOpen={mobileOpen}
          href="/design-projects"
          onClick={handleDrawerClose}
        />
        <NavItem
          label="Installation Projects"
          icon={faWrench}
          mobileOpen={mobileOpen}
          href="/installation-projects"
          onClick={handleDrawerClose}
        />
        <NavItem
          label="Installation Activity"
          icon={faChartColumn}
          mobileOpen={mobileOpen}
          href="/installation-activity"
          onClick={handleDrawerClose}
        />
        <NavItem
          label="Headquarters Users"
          icon={faPeopleCarryBox}
          mobileOpen={mobileOpen}
          href="/headquarters-users"
          onClick={handleDrawerClose}
        />
      </List>
    </Box>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: 'var(--safe-area-top)',
        paddingRight: 'var(--safe-area-right)',
        paddingBottom: 'var(--safe-area-bottom)',
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        color="default"
        sx={{
          display: { xs: 'block', sm: 'none' },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          paddingTop: 'var(--safe-area-top)',
          height: 'var(--safe-area-top) + 65px',
        }}
      >
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
          </Search>
          <ProfileMenu />
        </Toolbar>
      </AppBar>
      <MiniAppBar
        position="fixed"
        open={mobileOpen}
        color="default"
        sx={{
          display: { xs: 'none', sm: 'block' },
          paddingTop: 'var(--safe-area-top)',
          height: 'calc(var(--safe-area-top) + 65px)',
          paddingLeft: 'var(--safe-area-left)',
          paddingRight: 'var(--safe-area-right)',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: 5,
              ...(mobileOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
          </Search>
          <div style={{ flexGrow: 1 }} />
          <ProfileMenu />
        </Toolbar>
      </MiniAppBar>
      <SwipeableDrawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onOpen={handleDrawerOpen} // Function to handle the drawer opening
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </SwipeableDrawer>
      <MiniDrawer
        variant="permanent"
        open={mobileOpen}
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
      >
        {drawer}
      </MiniDrawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  )
}

function NavItem({
  label,
  icon,
  href,
  mobileOpen,
  onClick,
}: {
  label: string
  icon: IconDefinition
  href: string
  mobileOpen: boolean
  onClick: () => void
}) {
  const location = useLocation()
  const isActive = location.pathname === href

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        href={href}
        selected={isActive}
        onClick={onClick}
        sx={{
          minHeight: 48,
          justifyContent: mobileOpen ? 'initial' : 'right',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: mobileOpen ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <FontAwesomeIcon icon={icon} />
        </ListItemIcon>
        <ListItemText primary={label} sx={{ opacity: mobileOpen ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  )
}
