import { Org } from '@/lib/schemas/OrgSchema'

export default function OrgInfoTab({ org }: { org: Org }) {
  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Name</td>
          <td className="rounded-tr-lg border p-3">{org.name}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Email</td>
          <td className="border p-3">{org.email}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Phone</td>
          <td className="border p-3">{org.phoneNumber}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Plan Type</td>
          <td className="rounded-br-lg border p-3">{org.planType}</td>
        </tr>
      </tbody>
    </table>
  )
}
