import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useProjectMembersCollection } from '../DBProvider'
import { Project } from '@/lib/schemas/ProjectSchema'

export default function ProjectMembersTab({ project }: Readonly<{ project: Project }>) {
  const projectMembersCollection = useProjectMembersCollection()

  const { result: members, isFetching } = useRxQuery(
    projectMembersCollection.find({ selector: { projectId: project.id } }),
    {
      json: true,
    },
  )

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Member</th>
          </tr>
        </thead>
        <tbody>
          {members?.map((member) => {
            return (
              <tr key={member.userId}>
                <td>
                  <Link className="hover:text-primary" to={`members/${member.userId}`}>
                    {member.userId}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
