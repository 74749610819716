import { FavoriteTask } from '@/lib/schemas/FavoriteTaskSchema'

export default function FavoriteTaskInfoTab({ favoriteTask }: Readonly<{ favoriteTask: FavoriteTask }>) {
  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Name</td>
          <td className="rounded-tr-lg border p-3">{favoriteTask.name}</td>
        </tr>
      </tbody>
    </table>
  )
}
