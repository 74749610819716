import { CRS, LatLngBoundsExpression } from 'leaflet'
import { ImageOverlay, MapContainer, Marker, Tooltip } from 'react-leaflet'
import { useLocationsCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { Floor } from '@/lib/schemas/FloorSchema'

export default function FloorInfoTab({ floor }: { floor: Floor }) {
  const locationsCollection = useLocationsCollection()

  const { result: locations, isFetching } = useRxQuery(locationsCollection.find({ selector: { floorId: floor.id } }), {
    json: true,
  })

  if (isFetching) return <></>
  if (!floor.width || !floor.height) return null
  const width = floor.width
  const height = floor.height

  const imageBounds: LatLngBoundsExpression = [
    [0, 0], // Southwest coordinates
    [height, width], // Northeast coordinates
  ]

  console.log(floor.planImagePath)
  console.log(`${import.meta.env.VITE_IMGIX_BASE_URL}/${floor.planImagePath}`)
  return (
    <MapContainer
      className="map-container"
      center={[height / 2, width / 2]}
      zoom={-1}
      scrollWheelZoom={false}
      crs={CRS.Simple}
      minZoom={-5}
      maxZoom={3}
    >
      <ImageOverlay url={`${import.meta.env.VITE_IMGIX_BASE_URL}/${floor.planImagePath}`} bounds={imageBounds} />
      {locations?.map((location) => {
        if (!location.imageTop || !location.imageLeft) return null
        const x = (location.imageLeft / 100) * width
        const y = height - (location.imageTop / 100) * height
        console.log(x, y)
        return (
          <Marker key={location.id} position={[y, x]}>
            <Tooltip permanent={true}>{location.primaryDeviceId}</Tooltip>
          </Marker>
        )
      })}
    </MapContainer>
  )
}
