import { ProjectFloor } from '@/lib/schemas/ProjectFloorSchema'

export default function ProjectFloorInfoTab({ projectFloor }: { projectFloor: ProjectFloor }) {
  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="border text-right bg-muted p-3">Floor Id</td>
          <td className="border p-3">{projectFloor.floorId}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Plan Image Name</td>
          <td className="border p-3">{projectFloor.planImageName}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Plan Image Path</td>
          <td className="border p-3">{projectFloor.planImagePath}</td>
        </tr>
      </tbody>
    </table>
  )
}
