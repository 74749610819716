import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useServiceTicketCommentsCollection } from '../DBProvider'
import { ServiceTicket } from '@/lib/schemas/ServiceTicketSchema'
import { DeepReadonlyObject } from 'rxdb'

export default function ServiceTicketCommentsTab({
  serviceTicket,
}: {
  serviceTicket: DeepReadonlyObject<ServiceTicket>
}) {
  const serviceTicketCommentsCollection = useServiceTicketCommentsCollection()

  const { result: comments, isFetching } = useRxQuery(
    serviceTicketCommentsCollection.find({ selector: { serviceTicketId: serviceTicket.id } }),
    {
      json: true,
    },
  )

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {comments?.map((comment) => {
            return (
              <tr key={comment.id}>
                <td>
                  <Link className="hover:text-primary" to={`comments/${comment.id}`}>
                    {comment.comment}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
