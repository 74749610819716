import { Box, CircularProgress } from '@mui/material'
import logoWhite from '../svgs/logo_white.svg'

export default function Loader() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#003F77"
    >
      <img src={logoWhite} alt="SiteOwl" style={{ width: '300px', marginBottom: '20px' }} />
      <CircularProgress style={{ color: 'white' }} />
    </Box>
  )
}
