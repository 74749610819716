import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useProjectsCollection } from '../DBProvider'
import { Account } from '@/lib/schemas/AccountSchema'

export default function AccountProjectsTab({ account }: Readonly<{ account: Account }>) {
  const projectsCollection = useProjectsCollection()

  const { result: projects, isFetching } = useRxQuery(
    projectsCollection.find({ selector: { accountId: account.id } }),
    {
      json: true,
    },
  )

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Project</th>
          </tr>
        </thead>
        <tbody>
          {projects?.map((project) => {
            return (
              <tr key={project.id}>
                <td>
                  <Link className="hover:text-primary" to={`projects/${project.id}`}>
                    {project.name}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
