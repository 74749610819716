import { useParams } from 'react-router'
import TaskImagesTab from './TaskImagesTab'
import TaskInfoTab from './TaskInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useCurrentOrg } from '../auth/UserProvider'
import { Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import TabPanel from '../TabPanel'
import { useTasksCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info', 'images']

export default function TaskPage() {
  const tasksCollection = useTasksCollection()
  const navigate = useNavigate()
  const { taskId, tabId = 'info' } = useParams()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)

  const { result: tasks, isFetching } = useRxQuery(tasksCollection.findOne(taskId), { json: true })

  if (isFetching) return <></>

  const task = tasks?.[0]
  if (!task) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath = newValue === 0 ? `/tasks/${taskId}` : `/tasks/${taskId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{task.name}</h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Images" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <TaskInfoTab task={task} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <TaskImagesTab task={task} />
        </TabPanel>
      </Paper>
    </>
  )
}
