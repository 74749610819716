import * as React from 'react'
import { Link } from 'react-router'
import { useAccountMembersCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { Account } from '@/lib/schemas/AccountSchema'

export default function AccountMembersTab({ account }: Readonly<{ account: Account }>) {
  const accountMembersCollection = useAccountMembersCollection()

  const { result: members, isFetching } = useRxQuery(
    accountMembersCollection.find({ selector: { accountId: account.id } }),
    { json: true },
  )

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Member</th>
          </tr>
        </thead>
        <tbody>
          {members?.map((member) => {
            return (
              <tr key={member.userId}>
                <td>
                  <Link className="hover:text-primary" to={`members/${member.userId}`}>
                    {member.userId}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
