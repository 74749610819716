import { Account } from '@/lib/schemas/AccountSchema'

export default function AccountInfoTab({ account }: { account: Account }) {
  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Name</td>
          <td className="rounded-tr-lg border p-3">{account.name}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Email</td>
          <td className="border p-3">{account.email}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Phone</td>
          <td className="border p-3">{account.phoneNumber}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Is Active</td>
          <td className="border p-3">{account.isActive}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Is Local Save Enabled</td>
          <td className="rounded-br-lg border p-3">{account.settings.isLocalSaveEnabled}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Is Cabling Enabled</td>
          <td className="rounded-br-lg border p-3">{account.settings.isCablingEnabled}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Is Live System Access Enabled</td>
          <td className="rounded-br-lg border p-3">{account.settings.isLiveSystemAccessEnabled}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Is Local Save Enabled</td>
          <td className="rounded-br-lg border p-3">{account.settings.isLocalSaveEnabled}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Unlimited Plan In Reports</td>
          <td className="rounded-br-lg border p-3">{account.settings.isUnlimitedPlansInReportsEnabled}</td>
        </tr>
      </tbody>
    </table>
  )
}
