import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'
import { DataSource } from './DeviceVersionSchema'

const taskImageSchemaLiteral = {
  title: 'Task Image Schema',
  describe: 'Describes a task image',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
    },
    accountId: {
      type: 'string',
    },
    siteId: {
      type: 'string',
    },
    buildingId: {
      type: 'string',
    },
    floorId: {
      type: 'string',
    },
    defaultViewFilePath: {
      type: 'string',
    },
    defaultViewFileName: {
      type: 'string',
    },
    createdDate: {
      type: 'string',
      format: 'date-time',
    },
    createdByUserId: {
      type: 'string',
    },
    updatedDate: {
      type: 'string',
      format: 'date-time',
    },
    updatedByUserId: {
      type: 'string',
    },
    createdSource: {
      type: 'string',
    },
    mode: {
      type: 'string',
    },
    stage: {
      type: 'string',
    },
    uploadFileName: {
      type: 'string',
    },
    projectId: {
      type: 'string',
    },
    taskId: {
      type: 'string',
    },
    imageSource: {
      type: 'string',
      enum: Object.values(DataSource),
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'accountId', 'defaultViewFilePath', 'createdSource', 'mode', 'taskId', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(taskImageSchemaLiteral)

// aggregate the document type from the schema
export type TaskImage = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const taskImageSchema: RxJsonSchema<TaskImage> = taskImageSchemaLiteral

export type TaskImageDocument = RxDocument<TaskImage>

export type TaskImageCollection = RxCollection<TaskImage>
