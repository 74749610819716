import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const taskTypeSchemaLiteral = {
  title: 'Task Type Schema',
  describe: 'Describes a task type',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    name: {
      type: 'string',
    },
    iconName: {
      type: 'string',
    },
    iconUnicode: {
      type: 'string',
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'name', 'iconName', 'iconUnicode', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(taskTypeSchemaLiteral)

// aggregate the document type from the schema
export type TaskType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const taskTypeSchema: RxJsonSchema<TaskType> = taskTypeSchemaLiteral

export type TaskTypeDocument = RxDocument<TaskType>

export type TaskTypeCollection = RxCollection<TaskType>
