import 'external-svg-loader'
import UserProvider from './auth/UserProvider'
import Layout from './layout/Layout'
import FirebaseProvider from './FirebaseProvider'
import FronteggUserProvider from './auth/FronteggUserProvider'
import FirebaseUserProvider from './auth/FirebaseUserProvider'
import AuthGuard from './auth/AuthGuard'
import CssBaseline from '@mui/material/CssBaseline'
import ThemeModeProvider from './layout/ThemeModeProvider'
import AppNotificationListener from './AppNotificationListener'
import AppUrlListener from './AppUrlListener'
import { StatusBar } from '@capacitor/status-bar'
import { Capacitor } from '@capacitor/core'
import DBProvider from './DBProvider'

if (Capacitor.isNativePlatform()) {
  StatusBar.hide()
}

export default function App() {
  return (
    <ThemeModeProvider>
      <CssBaseline />
      <FirebaseProvider>
        <FronteggUserProvider>
          <FirebaseUserProvider>
            <DBProvider>
              <UserProvider>
                <AuthGuard>
                  <Layout />
                  <AppUrlListener />
                  <AppNotificationListener />
                </AuthGuard>
              </UserProvider>
            </DBProvider>
          </FirebaseUserProvider>
        </FronteggUserProvider>
      </FirebaseProvider>
    </ThemeModeProvider>
  )
}
