import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useSitesCollection } from '../DBProvider'
import { Account } from '@/lib/schemas/AccountSchema'

export default function AccountSitesTab({ account }: Readonly<{ account: Account }>) {
  const sitesCollection = useSitesCollection()

  const { result: sites, isFetching } = useRxQuery(sitesCollection.find({ selector: { accountId: account.id } }), {
    json: true,
  })

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Site</th>
          </tr>
        </thead>
        <tbody>
          {sites?.map((site) => {
            return (
              <tr key={site.id}>
                <td>
                  <Link className="hover:text-primary" to={`sites/${site.id}`}>
                    {site.name}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
