import ReactDOM from 'react-dom/client'
import App from './components/App'
import * as Sentry from '@sentry/capacitor'
import * as SentryReact from '@sentry/react'
import './index.css'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router'
import AccountsPage from './components/accounts/AccountsPage'
import BuildingPage from './components/building/BuildingPage'
import AccountPage from './components/account/AccountPage'
import DashboardPage from './components/dashboard/DashboardPage'
import DevicePage from './components/device/DevicePage'
import FavoritePage from './components/favorite/FavoritePage'
import FavoriteDevicePage from './components/favoriteDevice/FavoriteDevicePage'
import FavoriteTaskPage from './components/favoriteTask/FavoriteTaskPage'
import FloorPage from './components/floor/FloorPage'
import LocationPage from './components/location/LocationPage'
import NotFoundPage from './components/NotFoundPage'
import OrgPage from './components/org/OrgPage'
import ProjectPage from './components/project/ProjectPage'
import ProjectFloorPage from './components/projectFloor/ProjectFloorPage'
import ServiceTicketPage from './components/serviceTicket/ServiceTicketPage'
import SitePage from './components/site/SitePage'
import TaskPage from './components/task/TaskPage'

if (process.env.NODE_ENV !== 'development') {
  // initialize Sentry exception tracking
  Sentry.init(
    {
      dsn: 'https://c70257385ed207dd7f87d586efaeb3e3@o4507172132421632.ingest.us.sentry.io/4507172134977536',
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        Sentry.browserTracingIntegration(),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        Sentry.replayIntegration(),
      ],
      // use the git commit SHA as the release version
      release: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
      // set the environment
      environment: import.meta.env.VITE_NODE_ENV,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    }, // Forward the init method from @sentry/angular
    SentryReact.init,
  )
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/*" element={<App />}>
      <Route path="" element={<DashboardPage />} />
      <Route path="org" element={<OrgPage />} />
      <Route path="accounts" element={<AccountsPage />} />
      <Route path="accounts/:accountId/:tabId?" element={<AccountPage />} />
      <Route path="accounts/:accountId/projects/:projectId/:tabId?" element={<ProjectPage />} />
      <Route path="accounts/:accountId/projects/:projectId/floors/:floorId/:tabId?" element={<ProjectFloorPage />} />
      <Route path="accounts/:accountId/sites/:siteId/:tabId?" element={<SitePage />} />
      <Route path="accounts/:accountId/sites/:siteId/buildings/:buildingId/:tabId?" element={<BuildingPage />} />
      <Route
        path="accounts/:accountId/sites/:siteId/buildings/:buildingId/floors/:floorId/:tabId?"
        element={<FloorPage />}
      />
      <Route
        path="accounts/:accountId/sites/:siteId/buildings/:buildingId/floors/:floorId/locations/:locationId/:tabId?"
        element={<LocationPage />}
      />
      <Route
        path="accounts/:accountId/sites/:siteId/buildings/:buildingId/floors/:floorId/devices/:deviceId/:tabId?"
        element={<DevicePage />}
      />
      <Route
        path="accounts/:accountId/sites/:siteId/buildings/:buildingId/floors/:floorId/tasks/:taskId/:tabId?"
        element={<TaskPage />}
      />
      <Route path="accounts/:accountId/favorites/:favoriteId/:tabId?" element={<FavoritePage />} />
      <Route
        path="accounts/:accountId/favorites/:favoriteId/devices/:favoriteDeviceId/:tabId?"
        element={<FavoriteDevicePage />}
      />
      <Route
        path="accounts/:accountId/favorites/:favoriteId/tasks/:favoriteTaskId/:tabId?"
        element={<FavoriteTaskPage />}
      />
      <Route path="accounts/:accountId/service-tickets/:serviceTicketId/:tabId?" element={<ServiceTicketPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>,
  ),
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<RouterProvider router={router} />)
