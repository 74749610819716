import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useBuildingsCollection } from '../DBProvider'
import { Site } from '@/lib/schemas/SiteSchema'

export default function SiteBuildingsTab({ site }: Readonly<{ site: Site }>) {
  const buildingsCollection = useBuildingsCollection()

  const { result: buildings, isFetching } = useRxQuery(buildingsCollection.find({ selector: { siteId: site.id } }), {
    json: true,
  })

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Building</th>
          </tr>
        </thead>
        <tbody>
          {buildings?.map((building) => {
            return (
              <tr key={building.id}>
                <td>
                  <Link className="hover:text-primary" to={`buildings/${building.id}`}>
                    {building.name}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
