import { Project } from '@/lib/schemas/ProjectSchema'

export default function ProjectInfoTab({ project }: { project: Project }) {
  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Name</td>
          <td className="rounded-tr-lg border p-3">{project.name}</td>
        </tr>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Stage</td>
          <td className="rounded-tr-lg border p-3">{project.stage}</td>
        </tr>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Description</td>
          <td className="rounded-tr-lg border p-3">{project.description}</td>
        </tr>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Start Date</td>
          <td className="rounded-tr-lg border p-3">{project.startDate}</td>
        </tr>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Completion Date</td>
          <td className="rounded-tr-lg border p-3">{project.completionDate}</td>
        </tr>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Warranty Date</td>
          <td className="rounded-tr-lg border p-3">{project.warrantyDate}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Address</td>
          <td className="border p-3">{project.address}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Project Manager User Id</td>
          <td className="border p-3">{project.projectManagerUserId}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Designer User Id</td>
          <td className="border p-3">{project.designerUserId}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Design Status</td>
          <td className="border p-3">{project.designStatus}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Installation Status</td>
          <td className="border p-3">{project.installationStatus}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Is Archived</td>
          <td className="rounded-br-lg border p-3">{project.isArchived}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Is Published</td>
          <td className="border p-3">{project.isPublished}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Account Project Id</td>
          <td className="border p-3">{project.customProjectId}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Published By User Id</td>
          <td className="border p-3">{project.publishedByUserId}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Published Date</td>
          <td className="border p-3">{project.publishedDate}</td>
        </tr>
      </tbody>
    </table>
  )
}
