import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useTaskImagesCollection } from '../DBProvider'
import { Task } from '@/lib/schemas/TaskSchema'

export default function TaskImagesTab({ task }: Readonly<{ task: Task }>) {
  const taskImagesCollection = useTaskImagesCollection()

  const { result: images, isFetching } = useRxQuery(taskImagesCollection.find({ selector: { taskId: task.id } }), {
    json: true,
  })

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Images</th>
          </tr>
        </thead>
        <tbody>
          {images?.map((image) => {
            return (
              <tr key={image.id}>
                <td>
                  <Link className="hover:text-primary" to={`images/${image.id}`}>
                    {image.defaultViewFilePath}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
