import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material'
import React, { createContext, useContext, useState, useMemo } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router'
import { LinkProps } from '@mui/material/Link'

export type ColorMode = 'light' | 'dark' | 'system'

interface ThemeModeContextProps {
  colorMode: ColorMode
  setColorMode: (mode: ColorMode) => void
}

const ThemeModeContext = createContext<ThemeModeContextProps>({
  colorMode: 'system',
  setColorMode: () => {
    /* empty */
  },
})

export default function ThemeModeProvider({ children }: { children: React.ReactNode }) {
  const [colorMode, setColorMode] = useState<ColorMode>('system')
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const LinkBehavior = React.forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
  >((props, ref) => {
    const { href, ...other } = props
    // Map href (Material UI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
  })
  LinkBehavior.displayName = 'LinkBehavior'

  const theme = useMemo(() => {
    const mode = colorMode === 'system' ? (prefersDarkMode ? 'dark' : 'light') : colorMode

    if (mode === 'dark') {
      return createTheme({
        palette: {
          mode: 'dark',
        },
        typography: {
          fontFamily: 'Fira Sans',
        },
        components: {
          MuiLink: {
            defaultProps: {
              component: LinkBehavior,
            } as LinkProps,
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
              disableRipple: true,
            },
          },
        },
      })
    } else {
      return createTheme({
        palette: {
          background: {
            default: '#f5f5f5', // Light gray background for the overall app background
          },
          mode: 'light',
          primary: {
            main: '#003f77',
          },
          secondary: {
            main: '#00803d',
          },
          text: {
            primary: '#0d0d0d',
          },
          error: {
            main: '#da2955',
          },
          warning: {
            main: '#e8bc0a',
          },
          success: {
            main: '#4edd8e',
          },
        },
        typography: {
          fontFamily: 'Fira Sans',
        },
        components: {
          MuiLink: {
            defaultProps: {
              component: LinkBehavior,
            } as LinkProps,
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
              disableRipple: true,
            },
          },
        },
      })
    }
  }, [LinkBehavior, colorMode, prefersDarkMode])

  const contextValue = useMemo(
    () => ({
      colorMode,
      setColorMode,
    }),
    [colorMode],
  )

  return (
    <ThemeModeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeModeContext.Provider>
  )
}

export function useColorModeState() {
  return useContext(ThemeModeContext)
}
