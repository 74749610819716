import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useServiceTicketWatchersCollection } from '../DBProvider'
import { ServiceTicket } from '@/lib/schemas/ServiceTicketSchema'
import { DeepReadonlyObject } from 'rxdb'

export default function ServiceTicketWatchersTab({
  serviceTicket,
}: {
  serviceTicket: DeepReadonlyObject<ServiceTicket>
}) {
  const serviceTicketWatchersCollection = useServiceTicketWatchersCollection()

  const { result: watchers, isFetching } = useRxQuery(
    serviceTicketWatchersCollection.find({ selector: { serviceTicketId: serviceTicket.id } }),
    {
      json: true,
    },
  )

  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Watcher</th>
          </tr>
        </thead>
        <tbody>
          {watchers?.map((watcher) => {
            return (
              <tr key={watcher.userId}>
                <td>
                  <Link className="hover:text-primary" to={`watchers/${watcher.userId}`}>
                    {watcher.userId}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
