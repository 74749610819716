import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'
import { DataSource } from './DeviceVersionSchema'

const deviceImageSchemaLiteral = {
  title: 'Device Image Schema',
  describe: 'Describes a device image',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
    },
    accountId: {
      type: 'string',
    },
    siteId: {
      type: 'string',
    },
    buildingId: {
      type: 'string',
    },
    floorId: {
      type: 'string',
    },
    defaultViewFilePath: {
      type: 'string',
    },
    defaultViewFileName: {
      type: 'string',
    },
    createdDate: {
      type: 'string',
      format: 'date-time',
    },
    createdByUserId: {
      type: 'string',
    },
    updatedDate: {
      type: 'string',
      format: 'date-time',
    },
    updatedByUserId: {
      type: 'string',
    },
    createdSource: {
      type: 'string',
    },
    mode: {
      type: 'string',
    },
    stage: {
      type: 'string',
    },
    uploadFileName: {
      type: 'string',
    },
    projectId: {
      type: 'string',
    },
    deviceId: {
      type: 'string',
    },
    deviceVersionId: {
      type: 'string',
    },
    _deleted: { type: 'boolean' },
  },
  required: [
    'id',
    'orgId',
    'accountId',
    'defaultViewFilePath',
    'createdSource',
    'mode',
    'deviceId',
    'deviceVersionId',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(deviceImageSchemaLiteral)

// aggregate the document type from the schema
export type DeviceImage = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const deviceImageSchema: RxJsonSchema<DeviceImage> = deviceImageSchemaLiteral

export type DeviceImageDocument = RxDocument<DeviceImage>

export type DeviceImageCollection = RxCollection<DeviceImage>
