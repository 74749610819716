import { useParams } from 'react-router'
import FavoriteTaskInfoTab from './FavoriteTaskInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useCurrentOrg } from '../auth/UserProvider'
import { Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import TabPanel from '../TabPanel'
import { useFavoriteTasksCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info']

export default function FavoriteTaskPage() {
  const { favoriteTaskId, tabId = 'info' } = useParams()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)
  const favoriteTasksCollection = useFavoriteTasksCollection()

  const { result: favoriteTasks, isFetching } = useRxQuery(favoriteTasksCollection.findOne(favoriteTaskId), {
    json: true,
  })

  if (isFetching) return <></>

  const favoriteTask = favoriteTasks?.[0]
  if (!favoriteTask) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath =
      newValue === 0 ? `/favorite-tasks/${favoriteTaskId}` : `/favorite-tasks/${favoriteTaskId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{favoriteTask.name}</h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <FavoriteTaskInfoTab favoriteTask={favoriteTask} />
        </TabPanel>
      </Paper>
    </>
  )
}
