import { useParams } from 'react-router'
import FloorLocationsTab from './FloorLocationsTab'
import FloorInfoTab from './FloorInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import FloorPreferencesTab from './FloorPreferencesTab'
import FloorMapTab from './FloorMapTab'
import FloorDevicesTab from './FloorDevicesTab'
import { XMarkIcon } from '@heroicons/react/24/outline'
import FloorTasksTab from './FloorTasksTab'
import { useCurrentOrg } from '../auth/UserProvider'
import { Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import TabPanel from '../TabPanel'
import { useFloorsCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info', 'locations', 'user-preferences', 'devices', 'tasks', 'map']

export default function FloorPage() {
  const floorsCollection = useFloorsCollection()
  const { floorId, tabId = 'info' } = useParams()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)
  const currentOrg = useCurrentOrg()

  const { result: floors, isFetching } = useRxQuery(floorsCollection.findOne(floorId), { json: true })

  if (isFetching) return <></>

  const floor = floors?.[0]
  if (!floor) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath = newValue === 0 ? `/floors/${floorId}` : `/floors/${floorId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  return (
    <div className="flex flex-col h-screen">
      <h1 className="text-2xl font-bold pb-4">{floor.name}</h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Locations" />
          <Tab label="User Preferences" />
          <Tab label="Devices" />
          <Tab label="Tasks" />
          <Tab label="Map" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <FloorInfoTab floor={floor} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <FloorLocationsTab floor={floor} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <FloorPreferencesTab floor={floor} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <FloorDevicesTab floor={floor} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <FloorTasksTab floor={floor} />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <FloorMapTab floor={floor} />
        </TabPanel>
      </Paper>
    </div>
  )
}
