import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum OrgMemberRole {
  administrator = 'administrator',
  coordinator = 'coordinator',
  staff_member = 'staff_member',
  technician = 'technician',
}

export enum OrgMemberStatus {
  mail_sent = 'mail_sent',
  accepted = 'accepted',
}

const orgMemberSchemaLiteral = {
  title: 'Org Member Schema',
  describe: 'Describes an organization member',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: { type: 'string', maxLength: 36 },
    orgId: { type: 'string' },
    userId: { type: 'string' },
    roles: {
      type: 'array',
      items: {
        type: 'string',
        enum: Object.values(OrgMemberRole),
      },
    },
    status: {
      type: 'string',
      enum: Object.values(OrgMemberStatus),
    },
    emailReport: { type: 'boolean' },
    isDefaultAccountAdmin: { type: 'boolean' },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'userId', 'roles', 'status', 'emailReport', 'isDefaultAccountAdmin', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(orgMemberSchemaLiteral)

// aggregate the document type from the schema
export type OrgMember = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const orgMemberSchema: RxJsonSchema<OrgMember> = orgMemberSchemaLiteral

export type OrgMemberDocument = RxDocument<OrgMember>

export type OrgMemberCollection = RxCollection<OrgMember>
