import * as React from 'react'
import { Link } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useOrgMembersCollection } from '../DBProvider'
import { Org } from '@/lib/schemas/OrgSchema'

export default function OrgMembersTab({ org }: Readonly<{ org: Org }>) {
  const orgMembersCollection = useOrgMembersCollection()

  const { result: members, isFetching } = useRxQuery(orgMembersCollection.find({ selector: { orgId: org.id } }), {
    json: true,
  })

  const member = members?.[0]
  if (isFetching) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Member</th>
          </tr>
        </thead>
        <tbody>
          {members?.map((member) => {
            return (
              <tr key={member.userId}>
                <td>
                  <Link className="hover:text-primary" to={`members/${member.userId}`}>
                    {member.userId}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
