import { useParams } from 'react-router'
import ProjectFloorsTab from './ProjectFloorsTab'
import ProjectMembersTab from './ProjectMembersTab'
import ProjectInfoTab from './ProjectInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useCurrentOrg } from '../auth/UserProvider'
import { Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import TabPanel from '../TabPanel'
import { useProjectsCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info', 'floors', 'members']

export default function ProjectPage() {
  const projectsCollection = useProjectsCollection()
  const navigate = useNavigate()
  const currentOrg = useCurrentOrg()
  const { projectId, tabId = 'info' } = useParams()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)

  const { result: projects, isFetching } = useRxQuery(projectsCollection.findOne(projectId), { json: true })

  if (isFetching) return <></>

  const project = projects?.[0]
  if (!project) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath = newValue === 0 ? `/projects/${projectId}` : `/projects/${projectId}/${tabValues[newValue]}`
    navigate(newPath)
  }

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{project.name}</h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Floors" />
          <Tab label="Members" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <ProjectInfoTab project={project} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ProjectFloorsTab project={project} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ProjectMembersTab project={project} />
        </TabPanel>
      </Paper>
    </>
  )
}
