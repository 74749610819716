import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

const projectMemberSchemaLiteral = {
  title: 'Project Member Schema',
  describe: 'Describes a project member',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
    },
    accountId: {
      type: 'string',
    },
    projectId: {
      type: 'string',
    },
    userId: {
      type: 'string',
    },
    _deleted: { type: 'boolean' },
  },
  required: ['id', 'orgId', 'accountId', 'projectId', 'userId', '_deleted'],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(projectMemberSchemaLiteral)

// aggregate the document type from the schema
export type ProjectMember = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const projectMemberSchema: RxJsonSchema<ProjectMember> = projectMemberSchemaLiteral

export type ProjectMemberDocument = RxDocument<ProjectMember>

export type ProjectMemberCollection = RxCollection<ProjectMember>
