import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from 'rxdb'

export enum ProjectStage {
  design = 'design',
  installation = 'installation',
}

export enum ProjectStatus {
  open = 'open',
  in_progress = 'in_progress',
  done = 'done',
}

const projectSchemaLiteral = {
  title: 'Project Schema',
  describe: 'Describes a project',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    orgId: {
      type: 'string',
    },
    accountId: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    stage: {
      type: 'string',
      enum: Object.values(ProjectStage),
    },
    description: {
      type: 'string',
    },
    createdDate: {
      type: 'string',
      format: 'date-time',
    },
    createdByUserId: {
      type: 'string',
    },
    updatedDate: {
      type: 'string',
      format: 'date-time',
    },
    updatedByUserId: {
      type: 'string',
    },
    startDate: {
      type: 'string',
      format: 'date-time',
    },
    completionDate: {
      type: 'string',
      format: 'date-time',
    },
    warrantyDate: {
      type: 'string',
      format: 'date-time',
    },
    address: {
      type: 'string',
    },
    projectManagerUserId: {
      type: 'string',
    },
    designerUserId: {
      type: 'string',
    },
    designStatus: {
      type: 'string',
      enum: Object.values(ProjectStatus),
    },
    installationStatus: {
      type: 'string',
      enum: Object.values(ProjectStatus),
    },
    isArchived: {
      type: 'boolean',
    },
    isPublished: {
      type: 'boolean',
    },
    customProjectId: {
      type: 'string',
    },
    publishedByUserId: {
      type: 'string',
    },
    publishedDate: {
      type: 'string',
      format: 'date-time',
    },
    _deleted: { type: 'boolean' },
  },
  required: [
    'id',
    'orgId',
    'accountId',
    'name',
    'stage',
    'designStatus',
    'installationStatus',
    'isArchived',
    'isPublished',
    '_deleted',
  ],
  additionalProperties: false,
} as const // <- It is important to set 'as const' to preserve the literal type

const schemaTyped = toTypedRxJsonSchema(projectSchemaLiteral)

// aggregate the document type from the schema
export type Project = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>

// create the typed RxJsonSchema from the literal typed object.
export const projectSchema: RxJsonSchema<Project> = projectSchemaLiteral

export type ProjectDocument = RxDocument<Project>

export type ProjectCollection = RxCollection<Project>
