import React, { useState } from 'react'
import { Typography, Button, Paper, Tabs, Tab, Box, Switch, Link } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useCurrentOrg } from '../auth/UserProvider'
import { useAccountsCollection } from '../DBProvider'
import { orderBy } from 'firebase/firestore'
import { Account } from '@/lib/schemas/AccountSchema'
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarQuickFilter, gridClasses } from '@mui/x-data-grid'
import TabPanel from '../TabPanel'
import { useRxCollection, useRxQuery } from 'rxdb-hooks'

export default function AccountsPage() {
  const [tabValue, setTabValue] = useState(0)
  const currentOrg = useCurrentOrg()

  const accountsCollection = useAccountsCollection()
  const { result: accounts, isFetching } = useRxQuery(
    accountsCollection.find().where('orgId').equals(currentOrg.id).sort('name'),
    { json: true },
  )

  if (isFetching || !accounts) return <></>

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">Accounts</Typography>
          <Typography variant="body2">Accounts shows all Accounts associated with your Headquarters.</Typography>
        </Box>
        <Button variant="contained" startIcon={<FontAwesomeIcon icon={faPlus} />}>
          Create Account
        </Button>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Your Accounts" />
          <Tab label="Other Accounts" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <>
            <Typography variant="body2" sx={{ marginBottom: '16px' }}>
              Your Accounts shows all Accounts associated with Jason&apos;s Integrator that you can access.
            </Typography>
            <AccountsTable accounts={accounts} />
          </>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <>
            <Typography variant="body2" sx={{ marginBottom: '16px' }}>
              Other Accounts shows all Accounts associated with Jason&apos;s Integrator that you cannot access.
            </Typography>
            <AccountsTable accounts={[]} />
          </>
        </TabPanel>
      </Paper>
    </>
  )
}

function AccountsTable({ accounts }: { accounts: Account[] }) {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Account Name',
      flex: 1,
      renderCell: (params) => <Link href={`/accounts/${params.row.id}`}>{params.row.name}</Link>,
    },
    { field: 'assignedUsers', headerName: 'Assigned Users', flex: 1 },
    { field: 'email', headerName: 'Email Address', flex: 1 },
    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
    {
      field: 'includeInDailyReport',
      headerName: 'Include In Daily Report',
      flex: 1,
      renderCell: (params) => <Switch />,
    },
  ]

  const rows = accounts.map((account) => ({
    id: account.id,
    name: account.name,
    assignedUsers: '22',
    email: account.email,
    phoneNumber: account.phoneNumber,
    includeInDailyReport: true,
  }))

  return (
    <Box width="100%">
      <DataGrid
        columns={columns}
        rows={rows}
        autoHeight
        disableColumnSelector={true}
        disableColumnMenu={true}
        disableColumnSorting={true}
        disableColumnFilter={true}
        disableColumnResize={true}
        slots={{
          noRowsOverlay: () => (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Typography variant="body1" color="textSecondary">
                No accounts
              </Typography>
            </div>
          ),
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{
          // this removes the drag handles
          [`& .${gridClasses.columnSeparator}`]: {
            [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
              display: 'none',
            },
          },
          // this removes the cell focus
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
          },
          // remove the color from header cells
          [`& .${gridClasses.columnHeader}`]: {
            backgroundColor: 'white', // or 'inherit'
          },
          border: 'none',
        }}
      />
    </Box>
  )
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  )
}
