import { useParams } from 'react-router'
import DeviceImagesTab from './DeviceImagesTab'
import DeviceInfoTab from './DeviceInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useCurrentOrg } from '../auth/UserProvider'
import { Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import TabPanel from '../TabPanel'
import { useDevicesCollection } from '../DBProvider'
import { useRxQuery } from 'rxdb-hooks'
import { useNavigate } from 'react-router-dom'

const tabValues = ['info', 'images']

export default function DevicePage() {
  const { deviceId, tabId = 'info' } = useParams()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0)
  const devicesCollection = useDevicesCollection()

  const { result: devices, isFetching } = useRxQuery(devicesCollection.findOne(deviceId), { json: true })

  if (isFetching) return <></>

  const device = devices?.[0]
  if (!device) return <NotFoundPage />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newPath = newValue === 0 ? `/devices/${deviceId}` : `/devices/${deviceId}/${tabValues[newValue]}`
    navigate(newPath)
    setTabValue(newValue)
  }

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{device.id}</h1>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Images" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <DeviceInfoTab device={device} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <DeviceImagesTab device={device} />
        </TabPanel>
      </Paper>
    </>
  )
}
